const descriptions = [
  'Outsourcing the project management tasks helps organizations which are interested in maintaining their focus on their core competencies. Projects that are not properly managed can have a real negative impact on business performance.',
  'At Nearshore Code we apply management solutions  with an objective, professional approach allowing a faster time to market.',
  'We provide a fresh view to implement the required techniques during the project life cycle, from initiation, through planning, scheduling, monitoring and control, to termination.',
  'Our commitment towards quality reflects on the strong relationships we forge with our clients.',
];

const concepts = [
  {
    title: 'AGILE Management',
    content: 'It is applied to deliver software requirements in incremental iterations with greater visibility and continuous feedback, allowing you to react very quickly to bottlenecks and change.',
    bgColor: 'bg-concept-light-green',
  },
  {
    title: 'Project Managers',
    content: 'Providing appropriate project management talent to tackle high impact and time sensitive IT projects. The mixture of technical and soft skills of our managers are the key to success.',
    bgColor: 'bg-concept-normal-gray',
  },
];

export {
  descriptions, concepts,
};
